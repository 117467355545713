/* eslint-disable object-curly-newline */
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { CaptionDraft, EditingLine, SplitSubtitlePayload, SubtitleState } from '../types';
import { getDefaultLocalEditingLines } from '../utils';

type SubtitleContext = ActionContext<SubtitleState, RootState>;

const MAX_UNDO_COUNT = 15;

const initialState = (): SubtitleState => ({
  currentSubtitle: null,
  currentTime: 0,
  newCurrentTime: -1,
  isEnableSetNewCurrentTime: true,
  isEnableTogglePlayerPause: true,
  currentlyEditingSubtitleLineIndex: -1,
  currentlySelectedEditingSubtitleLineIndexes: [],
  localEditingLines: getDefaultLocalEditingLines(),
  zoomSteps: [0.25, 0.75, 2, 4, 8],
  currentPlaybackSpeed: 1.0,
  currentZoom: 2,
  isPlayerPaused: true,
  playbackSpeedList: [
    { value: 0.25, label: '0.25x' },
    { value: 0.5, label: '0.5x' },
    { value: 1.0, label: '1.0x' },
    { value: 1.5, label: '1.5x' },
    { value: 2.0, label: '2.0x' },
  ],
  isOpenPlaybackSpeedPopper: false,
  volume: 1.0,
  isOpenVolumePopper: false,
  volumeMuted: false,
  editingHistory: [],
  undoHistory: [],
  searchSubtitleKeyword: '',
  currentHighlightKeywordIndex: 0,
  totalFound: 0,
  lineErrorCount: 0,
  hasUnsavedChanges: false,
  saveSubtitleDraftState: setStateLoadingStatusByType(),
  publishSubtitleState: setStateLoadingStatusByType(),
});

const state = initialState();

const getters = {
  currentlyPlayingSubtitleLineIndex: (state: SubtitleState) => (
    state.localEditingLines.findIndex((line) => (state.currentTime >= line.startTime && state.currentTime <= line.endTime)) ?? -1
  ),
  canUndoLocalEditing: (state: SubtitleState) => state.editingHistory.length > 0,
  canRedoLocalEditing: (state: SubtitleState) => state.undoHistory.length > 0,
  filteredCaptionContent: (state: SubtitleState) => {
    const searchText = state.searchSubtitleKeyword;
    const captionContent = state.currentSubtitle?.captionContent;

    if (captionContent === undefined || searchText === '') return [];

    const captionContentWithIndex = captionContent.map((caption, index) => ({
      ...caption,
      index,
      totalFound: [...caption.text.matchAll(new RegExp(searchText, 'g'))].length,
    }));

    return captionContentWithIndex.filter((caption) => caption.totalFound > 0);
  },
  highlightLocalEditingLines: (state: SubtitleState) => (state.localEditingLines.map((line) => line.text.replaceAll(state.searchSubtitleKeyword, '<span class="highlight-keyword">$&</span>'))),
  highlightKeywordReferences: (state: SubtitleState) => {
    const searchText = state.searchSubtitleKeyword;

    if (state.localEditingLines === undefined || searchText === '') return [];
    const result = state.localEditingLines.map((editingLine) => ({ totalFound: [...editingLine.text.matchAll(new RegExp(searchText, 'g'))].length }));
    return result;
  },
  totalFound: (state: SubtitleState) => {
    if (state.searchSubtitleKeyword === '') return 0;
    return state.localEditingLines.reduce((previousValue, currentValue) => (
      previousValue + [...currentValue.text.matchAll(new RegExp(state.searchSubtitleKeyword, 'g'))].length
    ), 0);
  },
};

// mutation
const mutations = {
  setCurrentSubtitle(state: SubtitleState, value: CaptionDraft) {
    state.currentSubtitle = value;
    if (value?.captionContent && value.captionContent.length > 0) {
      state.localEditingLines = value.captionContent.map((object, index) => ({
        ...object,
        lineNo: index + 1,
        startTimeError: false,
        endTimeError: false,
      }));
    } else {
      state.localEditingLines = getDefaultLocalEditingLines();
    }
  },
  // !! not directly use this please use from `useSubtitleEditorPlayer.ts`
  setCurrentTime(state: SubtitleState, value: SubtitleState['currentTime']) {
    state.currentTime = value;
  },
  setIsEnableSetNewCurrentTime(state: SubtitleState, value: SubtitleState['isEnableSetNewCurrentTime']) {
    state.isEnableSetNewCurrentTime = value;
  },
  setCurrentZoom(state: SubtitleState, value: SubtitleState['currentZoom']) {
    state.currentZoom = value;
  },
  // !! not directly use this please use from `useSubtitleEditorPlayer.ts`
  setCurrentPlaybackSpeed(state: SubtitleState, value: SubtitleState['currentPlaybackSpeed']) {
    state.currentPlaybackSpeed = value;
  },
  setCurrentlyEditingSubtitleLineIndex(state: SubtitleState, value: SubtitleState['currentlyEditingSubtitleLineIndex']) {
    state.currentlyEditingSubtitleLineIndex = value;
  },
  setCurrentlySelectedEditingSubtitleLineIndexes(state: SubtitleState, value: SubtitleState['currentlySelectedEditingSubtitleLineIndexes']) {
    state.currentlySelectedEditingSubtitleLineIndexes = value;
  },
  // !! not directly use this please use from `useSubtitleEditorPlayer.ts`
  setIsPlayerPaused(state: SubtitleState, value: SubtitleState['isPlayerPaused']) {
    state.isPlayerPaused = value;
  },
  setLocalEditingLines(state: SubtitleState, value: SubtitleState['localEditingLines']) {
    state.localEditingLines = value;
  },
  setIsOpenPlaybackSpeedPopper(state: SubtitleState, value: SubtitleState['isOpenPlaybackSpeedPopper']) {
    state.isOpenPlaybackSpeedPopper = value;
  },
  setSearchSubtitleKeyword(state: SubtitleState, value: SubtitleState['searchSubtitleKeyword']) {
    state.searchSubtitleKeyword = value;
  },
  setCurrentHighlightKeywordIndex(state: SubtitleState, value: SubtitleState['currentHighlightKeywordIndex']) {
    state.currentHighlightKeywordIndex = value;
  },
  // !! not directly use this please use from `useSubtitleEditorPlayer.ts`
  setVolume(state: SubtitleState, value: SubtitleState['volume']) {
    state.volume = value;
  },
  // !! not directly use this please use from `useSubtitleEditorPlayer.ts`
  setVolumeMuted(state: SubtitleState, value: SubtitleState['volumeMuted']) {
    state.volumeMuted = value;
  },
  setIsOpenVolumePopper(state: SubtitleState, value: SubtitleState['isOpenVolumePopper']) {
    state.isOpenVolumePopper = value;
  },
  setTotalFound(state: SubtitleState, value: SubtitleState['totalFound']) {
    state.totalFound = value;
  },
  updateLocalEditingLine(state: SubtitleState, value: {
    index: number,
    line: EditingLine,
    /** @default true */
    isAutoAppendEditingHistory?: boolean,
  }) {
    if (value.isAutoAppendEditingHistory !== false) {
      state.editingHistory.push(state.localEditingLines);
      state.undoHistory = [];
    }

    state.localEditingLines = [
      ...state.localEditingLines.slice(0, value.index),
      value.line,
      ...state.localEditingLines.slice(value.index + 1),
    ];
    state.hasUnsavedChanges = true;
  },
  insertLineBetween(state: SubtitleState, data: { index: number, line?: EditingLine }) {
    const newLineIndex = data.index;
    const lineDurationSeconds = 3;

    state.localEditingLines = [
      ...state.localEditingLines.slice(0, newLineIndex),
      data.line ?? {
        lineNo: newLineIndex + 1,
        text: '',
        startTime: state.localEditingLines[newLineIndex - 1].endTime,
        endTime: state.localEditingLines[newLineIndex] ?
          state.localEditingLines[newLineIndex].startTime
          : state.localEditingLines[newLineIndex - 1].endTime + lineDurationSeconds,
        startTimeError: false,
        endTimeError: false,
      },
      ...state.localEditingLines.slice(newLineIndex),
    ];
  },
  addNewLine(state: SubtitleState) {
    const newLineIndex = state.localEditingLines.length;

    const startTime = newLineIndex !== 0 ? state.localEditingLines[newLineIndex - 1].endTime : undefined;
    const endTime = newLineIndex !== 0 ? state.localEditingLines[newLineIndex - 1].endTime + 3 : undefined;

    state.localEditingLines = [
      ...state.localEditingLines,
      ...getDefaultLocalEditingLines(newLineIndex + 1, startTime, endTime),
    ];
  },
  deleteLocalEditingLine(state: SubtitleState, index: number) {
    state.localEditingLines = state.localEditingLines.toSpliced(index, 1);
  },
  setIsEnableTogglePlayerPause(state: SubtitleState, value: boolean) {
    state.isEnableTogglePlayerPause = value;
  },
  appendEditingHistory(state: SubtitleState, snapshot: EditingLine[]) {
    state.hasUnsavedChanges = true;
    state.editingHistory.push(snapshot);
    state.undoHistory = [];

    if (state.editingHistory.length > MAX_UNDO_COUNT) {
      state.editingHistory.shift(); // Remove the oldest snapshot
    }
  },
  undoLocalEditing(state: SubtitleState) {
    if (state.editingHistory.length > 0) {
      const previousState = state.editingHistory.pop();
      state.undoHistory.push(state.localEditingLines);
      state.localEditingLines = previousState || [];
    }
  },
  redoLocalEditing(state: SubtitleState) {
    if (state.undoHistory.length > 0) {
      const nextState = state.undoHistory.pop();
      state.editingHistory.push(state.localEditingLines);
      state.localEditingLines = nextState || [];
    }
  },
  setLineErrorCount(state: SubtitleState, value: SubtitleState['lineErrorCount']) {
    state.lineErrorCount = value;
  },
  setSaveSubtitleDraftState(state: SubtitleState, value: SubtitleState['saveSubtitleDraftState']) {
    state.saveSubtitleDraftState = value;
  },
  setPublishSubtitleState(state: SubtitleState, value: SubtitleState['publishSubtitleState']) {
    state.publishSubtitleState = value;
  },
  resetSubtitleState(state: SubtitleState) {
    Object.assign(state, initialState());
  },
};

// action
const actions = {
  splitLocalEditingLineAtCursor({ commit, state }: SubtitleContext, payload: SplitSubtitlePayload) {
    const { index, textareaCursorPosition } = payload;
    const oldLine = state.localEditingLines[index];
    if (!oldLine) {
      return;
    }

    const baseText = oldLine.text;
    const oldLineText = baseText.slice(0, textareaCursorPosition);
    const oldLineTotalDuration = Math.abs(oldLine.endTime - oldLine.startTime);
    const splitDuration = oldLineTotalDuration / 2;
    const oldLineEndTime = oldLine.endTime - splitDuration;

    const updatedOldLine = {
      ...oldLine,
      text: oldLineText,
      endTime: oldLineEndTime,
    };

    const newLine = {
      lineNo: index + 2,
      text: baseText.slice(textareaCursorPosition),
      startTime: oldLineEndTime + 0.001,
      endTime: oldLine.endTime,
      error: false,
    };

    commit('updateLocalEditingLine', {
      index,
      line: updatedOldLine,
    });

    commit('insertLineBetween', {
      index: index + 1,
      line: newLine,
    });
  },
  async saveSubtitleDraft({ commit }: SubtitleContext, payload: CaptionDraft) {
    commit('setSaveSubtitleDraftState', setStateLoadingStatusByType({ type: 'loading' }));
    // FAKE API
    await Promise.resolve(payload);
    commit('setSaveSubtitleDraftState', setStateLoadingStatusByType({ type: 'success' }));

    setTimeout(() => {
      commit('setSaveSubtitleDraftState', setStateLoadingStatusByType({ type: 'idle' }));
    }, 3000);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
