import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bad-word-header" }
const _hoisted_2 = { key: 0 }

import { Caption } from '@/modules/videoCaption/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import isEmpty from 'lodash/isEmpty';
import {
  computed, ref, onBeforeUnmount, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import BadWordListModal from '@/modules/videoDetail/components/organisms/badWordListModal/BadWordListModal.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import TranslateVideoCaptionModal from '../translateVideoCaptionModal/TranslateVideoCaptionModal.vue';
import EditVideoCaptionSidebar from '../editVideoCaptionSidebar/EditVideoCaptionSidebar.vue';
import DeleteVideoCaptionModal from '../deleteVideoCaptionModal/DeleteVideoCaptionModal.vue';
import VideoCaptionRow from '../../molecules/videoCaptionRow/VideoCaptionRow.vue';
import ChangeCaptionStatusModal from '../changeVideoCaptionStatusModal/ChangeVideoCaptionStatusModal.vue';
import AddVideoCaptionModal from '../addVideoCaptionModal/AddVideoCaptionModal.vue';
import { downloadCaptionFile } from '../../../services';
import { useStore } from '../../../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoCaptionListSection',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const filter = computed(() => route.query);
const captionList = computed(() => store.state.captionList?.captionList);
const lastPage = computed(() => store.state.captionList?.lastPage || 1);
const loadingState = computed(() => store.state.captionList?.loadingState);
const isAutoGenEnabled = computed(() => store.state.teamFeatures?.teamFeatures?.['auto-generate-caption']);
const currentVideo = computed(() => store.state.video?.currentVideo);
const canUpdateVideo = computed(() => store.state.project.currentProject?.actions?.updateVideo);

const isShowAddCaptionModal = ref<boolean>(false);
const isShowEditCaptionSidebar = ref<boolean>(false);
const isShowTranslateCaptionModal = ref<boolean>(false);
const isShowDeleteCaptionModal = ref<boolean>(false);
const isShowBadWordListModal = ref<boolean>(false);
const isShowStatusModal = ref<boolean>(false);
const selectedCaption = ref<Caption | null>(null);
const selectedIndex = ref<number>(-1);
const addSubtitleMethod = ref<string>('');

// null state before loading or empty list after loading
const isCaptionEmpty = computed<boolean>(() => isEmpty(captionList.value));

watch(lastPage, (value) => {
  if (value !== null && Number(filter.value?.page) > Number(value)) {
    // invalid page number
    router.push({ path: route.path, query: { ...route.query, page: 1 } });
  }
});

// search caption
const onSearchCaption = (event: Event): void => {
  if (event.target && event.target instanceof HTMLInputElement) {
    const query = event.target.value.toLowerCase();
    router.push({ query: { q: query } });
  }
};

const searchQuery = computed(() => getQueryAsString(filter.value, 'q'));
const isSearchEmpty = computed(() => Boolean(searchQuery.value && isCaptionEmpty.value));

const searchInputValue = ref<string>('');

const onOpenEditSidebar = (index: number): void => {
  isShowEditCaptionSidebar.value = true;
  selectedIndex.value = index;
};

const onClickAutoGenCaption = (): void => {
  addSubtitleMethod.value = 'auto-gen';
  isShowAddCaptionModal.value = true;
};

const onOpenAddCaptionModal = (): void => {
  addSubtitleMethod.value = 'upload';
  isShowAddCaptionModal.value = true;
};

const onOpenTranslateCaptionModal = (caption: Caption): void => {
  isShowTranslateCaptionModal.value = true;
  selectedCaption.value = caption;
};

const onOpenDeleteCaptionModal = (caption: Caption): void => {
  isShowDeleteCaptionModal.value = true;
  selectedCaption.value = caption;
};

const onOpenBadwordListModal = (event: MouseEvent, caption: Caption): void => {
  event.stopPropagation();
  isShowBadWordListModal.value = true;
  selectedCaption.value = caption;
};

const onOpenStatusModal = (event: MouseEvent, index: number): void => {
  event.stopPropagation();
  isShowStatusModal.value = true;
  selectedIndex.value = index;
};

const downloadFile = async ({ key, originalFilename, customName }: Caption): Promise<void> => {
  const fileName = originalFilename || `${customName}.vtt`;
  try {
    const captionKey = key;
    const response = await downloadCaptionFile(captionKey);
    const captionFile = new File([response.data], `${fileName}`, {
      type: 'text/vtt',
    });
    const fileURL = URL.createObjectURL(captionFile);

    // create dummy  <a></a> for file download
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `${fileName}`;

    a.click();
    URL.revokeObjectURL(fileURL);
    // eslint-disable-next-line no-empty
  } catch (err) {
    // TODO: Handle error, create ui to display error message when download failed
    // console.log(err);
  }
};

const loadCaptionList = async () => {
  await store.dispatch('loadCaptionList', {
    ...(filter.value || {}),
    videoKey: currentVideo.value?.key,
  });
};

const unwatch = watch(
  () => filter.value,
  () => {
    loadCaptionList();
  },
);

onMounted(() => {
  if (!captionList.value) {
    loadCaptionList();
  }

  if (searchQuery.value) {
    searchInputValue.value = searchQuery.value;
  }

  store.dispatch('loadLanguageList');
});

onBeforeUnmount(() => {
  store.dispatch('destroyCaptionList');
});

onBeforeRouteLeave(() => {
  // watch can trigger on route leave and load caption list again
  // remove async watch to fix
  unwatch();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isShowAddCaptionModal.value)
      ? (_openBlock(), _createBlock(AddVideoCaptionModal, {
          key: 0,
          prefilledOption: addSubtitleMethod.value,
          "on-close": () => (isShowAddCaptionModal.value = false)
        }, null, 8, ["prefilledOption", "on-close"]))
      : _createCommentVNode("", true),
    (isShowEditCaptionSidebar.value && selectedIndex.value >= 0)
      ? (_openBlock(), _createBlock(EditVideoCaptionSidebar, {
          key: 1,
          captionIndex: selectedIndex.value,
          "on-close": () => {
      isShowEditCaptionSidebar.value = false;
      selectedIndex.value = -1;
    }
        }, null, 8, ["captionIndex", "on-close"]))
      : _createCommentVNode("", true),
    (isShowTranslateCaptionModal.value && selectedCaption.value)
      ? (_openBlock(), _createBlock(TranslateVideoCaptionModal, {
          key: 2,
          caption: selectedCaption.value,
          "on-close": () => {isShowTranslateCaptionModal.value = false}
        }, null, 8, ["caption", "on-close"]))
      : _createCommentVNode("", true),
    (isShowDeleteCaptionModal.value && selectedCaption.value)
      ? (_openBlock(), _createBlock(DeleteVideoCaptionModal, {
          key: 3,
          caption: selectedCaption.value,
          "on-close": () => {isShowDeleteCaptionModal.value = false}
        }, null, 8, ["caption", "on-close"]))
      : _createCommentVNode("", true),
    (isShowBadWordListModal.value && selectedCaption.value)
      ? (_openBlock(), _createBlock(BadWordListModal, {
          key: 4,
          caption: selectedCaption.value,
          "on-close": () => {isShowBadWordListModal.value = false}
        }, null, 8, ["caption", "on-close"]))
      : _createCommentVNode("", true),
    (isShowStatusModal.value && selectedIndex.value >= 0)
      ? (_openBlock(), _createBlock(ChangeCaptionStatusModal, {
          key: 5,
          "caption-index": selectedIndex.value,
          onCloseModal: () => {isShowStatusModal.value = false}
        }, null, 8, ["caption-index", "onCloseModal"]))
      : _createCommentVNode("", true),
    (loadingState.value?.status === 'loading')
      ? (_openBlock(), _createBlock(PreLoaderSection, { key: 6 }))
      : _createCommentVNode("", true),
    (loadingState.value?.status === 'success' && isCaptionEmpty.value && !isSearchEmpty.value)
      ? (_openBlock(), _createBlock(Section, {
          key: 7,
          id: "subtitles",
          class: "video-caption-section",
          title: _unref(t)('video.subtitle.title')
        }, {
          default: _withCtx(() => [
            _createVNode(MessageBox, {
              title: _unref(t)('video.subtitle.empty.title'),
              description: canUpdateVideo.value ? _unref(t)('video.subtitle.empty.subtitle'): '',
              onClickButton: onOpenAddCaptionModal,
              buttonPrefixIconClassName: "fas fa-plus",
              buttonTitle: canUpdateVideo.value ? _unref(t)('video.subtitle.add.button'): '',
              "button-style": "primary"
            }, _createSlots({ _: 2 }, [
              (canUpdateVideo.value && isAutoGenEnabled.value)
                ? {
                    name: "action",
                    fn: _withCtx(() => [
                      _createVNode(Button, {
                        buttonStyle: "text-primary",
                        size: "small",
                        onClick: onClickAutoGenCaption
                      }, {
                        "icon-prefix": _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
                        ])),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_unref(t)('video.subtitle.autoGen.button')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title", "description", "buttonTitle"])
          ]),
          _: 1
        }, 8, ["title"]))
      : (loadingState.value?.status === 'success')
        ? (_openBlock(), _createBlock(Section, {
            key: 8,
            id: "subtitles",
            class: "video-caption-section",
            title: _unref(t)('video.subtitle.title')
          }, {
            default: _withCtx(() => [
              _createVNode(DataTable, {
                onSearchData: onSearchCaption,
                searchInputPlaceholder: _unref(t)('video.subtitle.search.placeholder'),
                searchInputValue: searchInputValue.value,
                responsiveLayout: "b"
              }, _createSlots({ _: 2 }, [
                (canUpdateVideo.value)
                  ? {
                      name: "table-action",
                      fn: _withCtx(() => [
                        _createVNode(Button, {
                          type: "button",
                          buttonStyle: "secondary",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowAddCaptionModal.value = true))
                        }, {
                          "icon-prefix": _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
                          ])),
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('video.subtitle.add.button')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      key: "0"
                    }
                  : undefined,
                (!isCaptionEmpty.value && !isSearchEmpty.value)
                  ? {
                      name: "table-header",
                      fn: _withCtx(() => [
                        _createVNode(Row, null, {
                          default: _withCtx(() => [
                            _createVNode(Column, { minWidth: "140px" }, {
                              default: _withCtx(() => [
                                _createVNode(HeaderCell, {
                                  text: _unref(t)('video.subtitle.displayName.title')
                                }, null, 8, ["text"])
                              ]),
                              _: 1
                            }),
                            _createVNode(Column, { width: "60px" }, {
                              default: _withCtx(() => [
                                _createVNode(HeaderCell, {
                                  text: _unref(t)('video.subtitle.language.title')
                                }, null, 8, ["text"])
                              ]),
                              _: 1
                            }),
                            _createVNode(Column, { width: "150px" }, {
                              default: _withCtx(() => [
                                _createVNode(HeaderCell, {
                                  text: _unref(t)('video.subtitle.status.title')
                                }, null, 8, ["text"])
                              ]),
                              _: 1
                            }),
                            _createVNode(Column, { width: "80px" }, {
                              default: _withCtx(() => [
                                _createVNode(HeaderCell, {
                                  text: _unref(t)('video.subtitle.table.header.updatedAt')
                                }, null, 8, ["text"])
                              ]),
                              _: 1
                            }),
                            _createVNode(Column, { width: "85px" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                  _createTextVNode(_toDisplayString(_unref(t)('video.subtitle.badWords.title')) + " ", 1),
                                  _createVNode(Tooltip, null, {
                                    "tooltip-text": _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(t)('video.subtitle.table.header.badWords.helpText')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(Column, {
                              width: "100px",
                              isActionColumn: ""
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      key: "1"
                    }
                  : undefined,
                (!isSearchEmpty.value)
                  ? {
                      name: "table-content",
                      fn: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(captionList.value, (caption, index) => {
                          return (_openBlock(), _createBlock(VideoCaptionRow, {
                            key: `${caption.customName}-${index}`,
                            index: index,
                            onOnOpenEditSidebar: ($event: any) => (onOpenEditSidebar(index)),
                            onOnOpenTranslateCaptionModal: ($event: any) => (onOpenTranslateCaptionModal(caption)),
                            onOnOpenDeleteCaptionModal: ($event: any) => (onOpenDeleteCaptionModal(caption)),
                            onOnOpenBadwordListModal: (event) => onOpenBadwordListModal(event, caption),
                            onOnOpenStatusModal: (event) => onOpenStatusModal(event,index),
                            onDownloadFile: ($event: any) => (downloadFile(caption)),
                            readonly: !canUpdateVideo.value
                          }, null, 8, ["index", "onOnOpenEditSidebar", "onOnOpenTranslateCaptionModal", "onOnOpenDeleteCaptionModal", "onOnOpenBadwordListModal", "onOnOpenStatusModal", "onDownloadFile", "readonly"]))
                        }), 128))
                      ]),
                      key: "2"
                    }
                  : undefined,
                (lastPage.value > 1 && !isCaptionEmpty.value && !isSearchEmpty.value)
                  ? {
                      name: "table-footer",
                      fn: _withCtx(() => [
                        _createVNode(Pagination, {
                          lastPage: lastPage.value,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (isShowEditCaptionSidebar.value = false))
                        }, null, 8, ["lastPage"])
                      ]),
                      key: "3"
                    }
                  : undefined
              ]), 1032, ["searchInputPlaceholder", "searchInputValue"]),
              (isSearchEmpty.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(EmptySearch, { keyword: searchQuery.value }, null, 8, ["keyword"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
    (loadingState.value?.status === 'error')
      ? (_openBlock(), _createBlock(Section, { key: 9 }, {
          default: _withCtx(() => [
            _createVNode(ErrorMessage, {
              statusCode: loadingState.value?.error?.statusCode,
              title: loadingState.value?.error?.message
            }, null, 8, ["statusCode", "title"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})