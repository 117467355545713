import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createSlots as _createSlots, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "modal-body",
  id: "content-body",
  "data-test": "modal-body"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "modal-body",
  id: "content-body",
  "data-test": "modal-body"
}
const _hoisted_4 = {
  key: 1,
  class: "modal-footer",
  "data-test": "modal-footer"
}
const _hoisted_5 = {
  class: "modal-action",
  "data-test": "modal-action"
}

import {
  computed, onBeforeUnmount, onMounted, ref, toRefs, VNode,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ModalCloseButton from '@/modules/shared/components/atoms/modalComponents/ModalCloseButton.vue';
import ModalHeader from '@/modules/shared/components/atoms/modalComponents/ModalHeader.vue';
import InlineEdit from '@/modules/shared/components/molecules/inlineEdit/InlineEdit.vue';
import type { ModalProps } from './Modal.type';

const appendedBodyClass = 'modal-open';


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    size: { default: 'medium' },
    title: {},
    description: {},
    isShowModalHeader: { type: Boolean, default: true },
    isShowModalFooter: { type: Boolean, default: true },
    isSaving: { type: Boolean },
    hasInLineEdit: { type: Boolean, default: false },
    modalClass: {},
    width: {},
    zIndex: {},
    primaryButtonText: {},
    primaryButtonStyle: { default: 'primary' },
    isDisabledPrimaryButton: { type: Boolean },
    isShowPrimaryButton: { type: Boolean, default: true },
    onClickPrimaryButton: {},
    secondaryButtonText: {},
    secondaryButtonStyle: { default: 'text-secondary' },
    isDisabledSecondaryButton: { type: Boolean },
    isShowSecondaryButton: { type: Boolean, default: true },
    onClickSecondaryButton: {}
  },
  emits: ["modalClose", "update:title"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  size,
  title,
  description,
  isShowModalHeader,
  isShowModalFooter,
  isSaving,
  hasInLineEdit,
  modalClass,
  width,
  zIndex,
  primaryButtonText,
  primaryButtonStyle,
  isDisabledPrimaryButton,
  isShowPrimaryButton,
  onClickPrimaryButton,
  secondaryButtonText,
  secondaryButtonStyle,
  isDisabledSecondaryButton,
  isShowSecondaryButton,
  onClickSecondaryButton,
} = toRefs(props);

const emit = __emit;

/* eslint-disable func-call-spacing, no-spaced-func */
const slots = _useSlots();

const { t } = useI18n();
const isShowInLineEdit = ref(false);

const modalSizeClass = computed(() => {
  if (size.value) {
    return `modal-${size.value} ${modalClass.value}`;
  }
  return modalClass.value;
});

const styles = computed(() => {
  if (width.value) {
    return {
      width: typeof width.value === 'number' ? `${width.value}px` : width.value,
    };
  }

  return undefined;
});

const modalContainerStyles = computed(() => {
  if (zIndex.value) {
    return {
      'z-index': `${zIndex.value}`,
    };
  }

  return undefined;
});

function onCloseModal() {
  emit('modalClose');
  document.body.classList.remove('modal-open');
}

function onEditName(name: string) {
  emit('update:title', name);
  isShowInLineEdit.value = false;
}

function onCancelEditName() {
  isShowInLineEdit.value = false;
}

function secondaryButtonAction() {
  if (onClickSecondaryButton.value) {
    onClickSecondaryButton.value();
  } else {
    emit('modalClose');
  }
}

function keyListener(e: KeyboardEvent) {
  if (e.code === 'Escape') {
    onCloseModal();
  }
}

onMounted(() => {
  document.body.classList.add(appendedBodyClass);
  window.addEventListener('keydown', keyListener);
});

onBeforeUnmount(() => {
  document.body.classList.remove(appendedBodyClass);
  window.removeEventListener('keydown', keyListener);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      tabindex: "0",
      class: "modal-container",
      id: "modal-container",
      style: _normalizeStyle(modalContainerStyles.value)
    }, [
      (!_unref(onClickPrimaryButton))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["modal-content", modalSizeClass.value]),
            style: _normalizeStyle(styles.value),
            "data-test": "modal-content"
          }, [
            _createVNode(ModalCloseButton, { onCloseModal: onCloseModal }),
            (_unref(isShowModalHeader))
              ? (_openBlock(), _createBlock(ModalHeader, {
                  key: 0,
                  title: _unref(title),
                  description: _unref(description)
                }, null, 8, ["title", "description"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "body")
            ])
          ], 6))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_unref(onClickPrimaryButton) && _unref(onClickPrimaryButton)(...args)), ["prevent"])),
            class: _normalizeClass(["modal-content", modalSizeClass.value]),
            style: _normalizeStyle(styles.value),
            "data-test": "modal-content-form"
          }, [
            _createVNode(ModalCloseButton, { onCloseModal: onCloseModal }),
            (_unref(isShowModalHeader))
              ? (_openBlock(), _createBlock(ModalHeader, {
                  key: 0,
                  title: _unref(title),
                  description: _unref(description),
                  isShowInLineEdit: isShowInLineEdit.value
                }, {
                  default: _withCtx(() => [
                    (_unref(hasInLineEdit) && _unref(title))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          (!isShowInLineEdit.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "edit-button",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowInLineEdit.value = true))
                              }, _cache[2] || (_cache[2] = [
                                _createElementVNode("i", { class: "fas fa-pen edit-icon" }, null, -1)
                              ])))
                            : (_openBlock(), _createBlock(InlineEdit, {
                                key: 1,
                                value: _unref(title),
                                onSubmit: onEditName,
                                onClose: onCancelEditName
                              }, null, 8, ["value"]))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title", "description", "isShowInLineEdit"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            (_unref(isShowModalFooter))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "footer"),
                  _createElementVNode("div", _hoisted_5, [
                    (_unref(isShowSecondaryButton))
                      ? (_openBlock(), _createBlock(Button, {
                          key: 0,
                          dataTest: "secondary-btn",
                          "button-classes": "secondary-btn",
                          type: "button",
                          onClick: _withModifiers(secondaryButtonAction, ["stop"]),
                          disabled: _unref(isDisabledSecondaryButton),
                          buttonStyle: _unref(secondaryButtonStyle)
                        }, _createSlots({
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(secondaryButtonText) || _unref(t)('common:button.cancel')), 1)
                          ]),
                          _: 2
                        }, [
                          (slots['secondary-btn-prefix'])
                            ? {
                                name: "icon-prefix",
                                fn: _withCtx(() => [
                                  _renderSlot(_ctx.$slots, "secondary-btn-prefix")
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["disabled", "buttonStyle"]))
                      : _createCommentVNode("", true),
                    (_unref(isShowPrimaryButton))
                      ? (_openBlock(), _createBlock(Button, {
                          key: 1,
                          dataTest: "primary-btn",
                          "button-classes": "primary-btn",
                          type: "submit",
                          disabled: _unref(isDisabledPrimaryButton),
                          buttonStyle: _unref(primaryButtonStyle),
                          isSaving: _unref(isSaving)
                        }, _createSlots({
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(primaryButtonText) || _unref(t)('common:button.save')), 1)
                          ]),
                          _: 2
                        }, [
                          (slots['primary-btn-prefix'])
                            ? {
                                name: "icon-prefix",
                                fn: _withCtx(() => [
                                  (slots['primary-btn-prefix'])
                                    ? _renderSlot(_ctx.$slots, "primary-btn-prefix", { key: 0 })
                                    : _createCommentVNode("", true)
                                ]),
                                key: "0"
                              }
                            : undefined,
                          (slots['primary-btn-suffix'])
                            ? {
                                name: "icon-suffix",
                                fn: _withCtx(() => [
                                  (slots['primary-btn-suffix'])
                                    ? _renderSlot(_ctx.$slots, "primary-btn-suffix", { key: 0 })
                                    : _createCommentVNode("", true)
                                ]),
                                key: "1"
                              }
                            : undefined
                        ]), 1032, ["disabled", "buttonStyle", "isSaving"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 38)),
      _createElementVNode("div", {
        onClick: onCloseModal,
        class: "modal-overlay"
      })
    ], 4)
  ]))
}
}

})